.home-page-div{
    padding: 16%;
    height: 100%;
    width: 100%;
    justify-items: center;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.main-title{
    font-weight: 500;
    font-size: 2.25rem;
}

.main-title-1{
    font-weight: 400;
    font-size: 1.75rem;
}


.main-subtitle{
    font-weight: 300;
    font-size: 1.25rem;
}

.sentence{
    font-weight: 300;
    font-size: 1.15rem;
}

.menu{
    font-weight: 300;
}

.btn{
    font-size: 14px;
    align-items: center;
    text-align: center;
    display: flex;
    position: relative;
    text-decoration: none;
    color: #53565a;
    font-weight: 500;
}

.left-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 30px;
}

.home-image{
    width: 40%;
}