@tailwind base;
@tailwind components;
@tailwind utilities;

body, html, #root {
    height: 100%;
}

.MuiInputLabel-root {
    color: black !important;
}

.MuiSelectLabel-root {
    color: black;
}

::selection {
    background-color: #830000;
    color: white;
}