.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130vh;
    background-color: #FBFAF5;
    padding: 20px;
}

@media (max-width: 700px) {
    .contact-page {
        margin-top: 24%;
    }
 }

.contact-content {
    text-align: center;
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
}

.contact-title {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
    color: #333;
}

.divider {
    margin: 20px 0;
}

.contact-subtitle {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    color: #555;
}

.contact-description {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 30px;
    color: #777;
}

.contact-buttons .contact-button {
    text-transform: none;
    font-family: 'Montserrat', sans-serif;
    width: 50%;
}

.contact-buttons .MuiButton-containedPrimary {
    background-color: #c8102e;
}

.contact-buttons .MuiButton-containedSecondary {
    background-color: #833AB4;
    background: linear-gradient(135deg, #833AB4 0%, #FD1D1D 50%, #FCB045 100%);
}

.form-title {
    font-family: 'Montserrat', sans-serif;
    margin: 20px 0;
    color: #333;
}

.feedback-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.feedback-form-field {
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
}

.feedback-form-button {
    text-transform: none;
    font-family: 'Montserrat', sans-serif;
    background-color: #c8102e;
}

.space-above {
    margin-top: 20px;
}

.space-above-title {
    margin-bottom: 20px;
}