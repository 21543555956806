.kvkk{
    margin-top: 10px;
}

.kvkk-text{
    margin: 20px 0;
    font-size: 14px;
    color: #c8102e;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.kvkk-accepted-text{
    margin: 20px 0;
    font-size: 14px;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}


.ai-response-text{
    margin-top: 1rem;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
}

.ai-text{
    font-weight: 400;
    display: inline;
}

.ai-bold-text{
    font-weight: 600;
    display: inline;

}