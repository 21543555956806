.menu{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.btn{
    font-size: 14px;
    align-items: center;
    text-align: center;
    display: flex;
    position: relative;
    text-decoration: none;
    color: #53565a;
    font-weight: 500;
}


.menu.btn {
    position: relative;
    padding: auto;
    color: #c8102e;
}

.menu.register{
    color: #c8102e;
    border: 2px solid #c8102e;
    border-radius: 25px;
    padding: 4px 8px 4px 8px;
    margin: 8px 16px 8px 24px;
    width: auto;
    font-size: 14px;
    font-weight: 500;
}

.menu.responsive.regiter{
    background-color: #53565a;
    color: white;
}

.menu.register:hover{
    color: white;
    background-color: #9e011b;
}

.menu.btn::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 0;
    height: 1px;
    background-color: #ff6f6f;
    transition: width 0.2s;
    transform: scaleX(1.5);
}

.bar-stack{
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.responsive{
    margin:20px;
    justify-content: space-between;
    display: flex;
    flex-direction: row-reverse;
    font-size: 14px;
    align-items: center;
    text-align: center;
}



@media (max-width: 900px) {

   .btn{
       font-size: 12px;
   }
}