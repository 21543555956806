.register-div{
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("../../assets/background/main3.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.register{
    width: 50%;
    height: auto;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding-left: 2%;
    padding-right: 2%;
}


.register-logo{
    width: 70%;
}

.button{
    margin: 0 0 10px 0;
    background-color: rgb(255, 255, 255);

}

.register-text{
    margin: 20px 0;
    font-size: 20px;
    color: #9d0000;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.kvkk{
    margin-top: 10px;
}

.kvkk-text{
    margin: 20px 0;
    font-size: 14px;
    color: #9d0000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.kvkk-accepted-text{
    margin: 20px 0;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.grade{
    color: black;
}

.modal-qr{
    width: 60%;
    padding: 4%;
    justify-content: center;
    background-color: white;
    border-radius: 16px;
}

@media (max-width: 600px) {
    .register{
        width:80%
    }

}